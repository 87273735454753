<template>
  <basic-container>
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="$t('cip.plat.sys.dataStandard.title.indexHeadTitle')"
                 @head-add="headAdd"
                 @head-delete="handDelete">
    </head-layout>
    <grid-head-layout
      ref="gridHeadLayout"
      v-model="searchForm"
      :search-columns="searchColumns"
      @grid-head-search="searchChange"
      @grid-head-empty="searchReset">
    </grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @gird-handle-select-click="selectionChange"
      @grid-row-detail-click="rowView"
      @row-remove="rowRemove"
      @row-edit="rowEdit"
    >
    </grid-layout>
  </basic-container>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {getList, remove} from "@/api/system/dataStandard";

export default {
  components: {
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      selectionList: [],
      searchForm: {},
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      searchColumns: [
        {
          prop: "dictCode",
          width: 100,
          span:4,
          placeholder: this.$t("cip.plat.sys.dataStandard.field.dictCode"),
        },
        {
          prop: "dictName",
          width: 100,
          span:4,
          placeholder: this.$t("cip.plat.sys.dataStandard.field.dictName"),
        },
        {
          prop: "englishAbbreviation",
          width: 100,
          span:4,
          placeholder: this.$t("cip.plat.sys.dataStandard.field.englishAbbreviation"),
        },
        {
          prop: "englishName",
          width: 100,
          span:4,
          placeholder: this.$t("cip.plat.sys.dataStandard.field.englishName"),
        },
        {
          prop: "synonym",
          width: 100,
          span:4,
          placeholder: this.$t("cip.plat.sys.dataStandard.field.synonym"),
        },
      ],
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      // if (this.permission.client_add) {
      result.push({
        label: this.$t('cip.cmn.btn.addBtn'),
        emit: "head-add",
        type: "button",
        icon: ""
      });
      // }
      // if (this.permission.client_delete) {
      result.push({
        label: this.$t('cip.cmn.btn.delBtn'),
        emit: "head-delete",
        type: "button",
        icon: ""
      });
      // }
      return result;
    },
    gridRowBtn() {
      let buttonBtn = [];
      // if (this.permission.knowledge_edit) {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.editBtn`),
            emit: "row-edit",
            type: "button",
            icon: ""
          });
      // }
      // if (this.permission.knowledge_delete) {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.delBtn`),
            emit: "row-remove",
            type: "button",
            icon: ""
          })
      // }
      return buttonBtn;
    },
    tableOptions() {
      return {
        linklabel: "dictCode",
        column: [
          {
            label: this.$t('cip.plat.sys.dataStandard.field.dictCode'),
            prop: "dictCode",
            overHidden: true,
            placeholder: this.$t("cip.plat.sys.dataStandard.field.dictCode"),
          },
          {
            label: this.$t('cip.plat.sys.dataStandard.field.dictName'),
            prop: "dictName",
            overHidden: true,
            placeholder: this.$t("cip.plat.sys.dataStandard.field.dictName"),
          },
          {
            label: this.$t("cip.plat.sys.dataStandard.field.englishAbbreviation"),
            prop: "englishAbbreviation",
            overHidden: true,
            placeholder: this.$t("cip.plat.sys.dataStandard.field.englishAbbreviation"),
          },
          {
            label: this.$t("cip.plat.sys.dataStandard.field.englishName"),
            prop: "englishName",
            overHidden: true,
            placeholder: this.$t("cip.plat.sys.dataStandard.field.englishName"),
          },
          {
            label: this.$t("cip.plat.sys.dataStandard.field.synonym"),
            prop: "synonym",
            overHidden: true,
            placeholder: this.$t("cip.plat.sys.dataStandard.field.synonym"),
          },
        ]
      }
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    }
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    headAdd() {
      this.$router.push({
        path: '/system/dataStandard/add',
        query: {
          type: 'add',
        }
      });
    },
    rowEdit(row) {
      this.$router.push({
        path: '/system/dataStandard/edit',
        query: {
          id: row.id,
          type: 'edit',
        }
      })
    },
    rowView(row) {
      this.$router.push({
        path: '/system/dataStandard/view',
        query: {
          id: row.id,
          type: 'view',
        }
      })
    },
    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
          this.$refs.crud.toggleSelection();
        });
    },
    handDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
          this.$refs.crud.toggleSelection();
        });
    },
    selectionChange(list) {
      this.selectionList = list;
      console.log(this.selectionList,"666")
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then((res) => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.tableData = data.records;
          this.tableLoading = false;
        })
    },
  }
};
</script>

<style>
</style>
